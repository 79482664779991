import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import { persistConfig } from "redux/_setup/persistence";
import { RootReducer, rootReducer } from "redux/_setup/reducers";

export const store = configureStore({
  reducer: persistReducer<RootReducer>(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: [/^modal.*$/, /^uploadImage\..*\.file$/, /^uploadImage\..*\.uploadErr$/],
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          "modal/openDialog",
          "modal/openContextMenu",
          "modal/showShellPreview",
          "ui/appendDevPageLog",
          "uploadImage/setFile",
        ],
        ignoredActionPaths: ["payload.err"],
      },
    }),
});

if (process.env.NODE_ENV === "development") {
  // @ts-ignore
  window.store = store;
}

export const persistor = persistStore(store);

export type GetState = typeof store.getState;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export function MakeState<T>(state: T): T {
  // Helper to define slice state in a type-safe way.
  // Primarily used for inline declaration.
  return state;
}
