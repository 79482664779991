import localForage from "localforage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

const slicesToPersist = ["shellForm", "settings"];

export const persistConfig = {
  key: "root",
  version: 1,
  storage: localForage,
  stateReconciler: autoMergeLevel2,
  whitelist: slicesToPersist,
};

export const playerPersistConfig = {
  key: "shells",
  version: 1,
  storage: localForage,
  whitelist: ["recentShells", "ownShells", "modShells"],
  stateReconciler: autoMergeLevel2,
};
