import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Post from "components/post";
import { getPost } from 'utils/feed-parser'
import './style.scss';

type Post = {
  id: number;
  title: string;
  thumbnail: string;
  description: string;
};

const PostPage = () => {
  const { postId } = useParams();
  const [post, setPost] = useState<Post>();  
  
  useEffect(() => {
    if(postId){
      setPost(getPost(postId))
    }
  },[postId])

 const PostHeader = () => {
  return (
    <>    
    <div className="post-header">
      <div className="post-header__navigation">
        <div className="post-header__navigation-back" onClick={() => window.history.back()}>⮜</div>
        <div className="post-header__title">
          Post
        </div>        
      </div>
    </div>
    </>
  )
 }

  return (post && 
    <>
      <PostHeader />
      <Post post={post} />
    </>
  );
};

export default PostPage;
