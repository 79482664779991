import { useRef, useState } from 'react';
import "./style.scss";

const ExplorePage = () => {

  const Placeholder = () => {
    const divRef = useRef<any>(null);
    const [isRemoved, setIsRemoved] = useState(false);
    let startX: any = null;
    const swipeTreshold = 200;
  
    const handleTouchStart = (e: any) => {
      startX = e.touches[0].clientX;
    };
  
    const handleTouchMove = (e: any) => {
      if (startX !== null) {
        const currentX = e.touches[0].clientX;
        const deltaX = currentX - startX;
        
        divRef.current.style.transform = `translateX(${deltaX}px)`;
        if(deltaX<(swipeTreshold*-1)){
          divRef.current.style.background = '#FF0000';          
        }else if(deltaX>swipeTreshold){
          divRef.current.style.background = '#00FF00';
        }else{
          divRef.current.style.background = 'var(--color-text)';
        }
      }
    };
  
    const handleTouchEnd = (e:any) => {
      if (startX !== null && divRef.current) {
        const deltaX = e.changedTouches[0].clientX - startX;

        if(deltaX<(swipeTreshold*-1) || deltaX>swipeTreshold){
          setIsRemoved(true);
        }else{
          startX = null;
          divRef.current.style.transform = 'translateX(0)';
        }
      }
    };

    if (isRemoved) {
      return null;
    }

    return (
      <>
        <div 
          className="placeholder" 
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          ref={divRef}
        >
          Swipable on Touch Displays
          <div className="swipe-left" onClick={() => setIsRemoved(true)} />
          <div className="swipe-right" onClick={() => setIsRemoved(true)} />
        </div>
      </>
    )
  }

  return (
    <>    
      <div className="content__header"><h1>Explore</h1></div>
      <div className="swipe__wrapper">
        <Placeholder />
        <Placeholder />
        <Placeholder />
        <Placeholder />
      </div>      
    </>
  );
};

export default ExplorePage;
