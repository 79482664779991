import { useState, useEffect } from 'react';
import Post from 'components/post';
import { getPosts } from 'utils/feed-parser'
import "./style.scss";

const Content = (props: any) => {
  const { user, home } = props;
  const [posts, setPosts] = useState([])
  const [fetchingPosts, setFetchingPosts] = useState(false)

  useEffect(() => {
    if(home || user){      
      setPosts([])
      setTimeout(() => {
        const posts = getPosts(0,10, home ? null : user.handle)
        setPosts(posts)
      },3000)
    }
  }, [user])

  useEffect(() => {
    function handleScroll() {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement;

      if (scrollTop + clientHeight >= scrollHeight - (clientHeight/2)) {
        if(!fetchingPosts && (home || user)){
          setFetchingPosts(true);
          const newPosts = getPosts(posts.length,10, home ? null : user.handle)
          setPosts(posts.concat(newPosts))
          setFetchingPosts(false);
        }
      }
    }

    if(posts){
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [posts]);

  return (
    <div className="content">
      <div className="content__input"></div>
      {posts && (
        <div className="content__posts">
          <>
            {<Post
                post={{
                link: "",
                channel: { title: 'Steve', thumbnail: "https://arweave.net/yYBrm-HrDkoWx6c5GvPhdPR3jlzUsQH-IJYYU_1lMwg", handle: "@steve"},
                title: "moon",
                description: "moooooon",
                uri: "https://arweave.net/fWOyHLy2HeZBRNJEAIMo1JdHLAqIMbxFPFzQWNCvo1o",
                thumbnail: "https://arweave.net/yYBrm-HrDkoWx6c5GvPhdPR3jlzUsQH-IJYYU_1lMwg",
                timestamp: Date.now(),
              }}
                key={"inserted"} />}
            {posts.map((post, i) => (<Post post={post} key={i} />))}
          </>
      </div>
      )}

    </div>
  );
};

export default Content;
