import { useEffect, useState, useRef } from 'react';
import Icon from 'components/icons'
import { useNavigate } from 'react-router-dom';
import "./style.scss";

const Navigation = () => {
  const [page, setPage] = useState('home');
  const [theme, setTheme] = useState('dark');
  const [showMobileMenu, setShowMobileMenu] = useState(false);  
  const navigation = useRef(null);
  const navigate = useNavigate();

  function toggleTheme(){
    const currentTheme = document.body.getAttribute("theme");
    const newTheme = currentTheme === 'dark' ? 'bright' : 'dark';
    setTheme(newTheme);
    document.body.setAttribute("theme", newTheme);
  }

  function onSetPage(page: string){
    setPage(page === '' ? 'home' : page);
    navigate(page);
    if(showMobileMenu) setShowMobileMenu(false);
  }

  function handleClickOutside(e:any) {
    if (navigation.current && !(navigation.current as any).contains(e.target)) {
      if(showMobileMenu) setShowMobileMenu(false);
    }
  };

  useEffect(() => {
    if(showMobileMenu){
      document.addEventListener('click', handleClickOutside);
    }else{
      document.removeEventListener('click', handleClickOutside);
    }
  }, [showMobileMenu]);


  return (
    <div className={`navigation__wrapper ${showMobileMenu && 'navigation__wrapper--blur'}`}>
      <div className="navigation" ref={navigation}>
        <div className='navigation__logo'>
          <div className="logo__wrapper" onClick={() => onSetPage('')}><div className="logo">P</div> laceholder</div>
          <div className={`theme-toggle ${theme === 'dark' ? 'theme-toggle--dark' : '' }`} onClick={() => toggleTheme()}>
              <Icon icon="bright"/>
              <Icon icon="dark"/>
            <div/>
          </div>
          <div className='quick-navigation__entries'>
            <div className={`quick-navigation__entry ${page === 'notifications' ? 'navigation__entry--active' : ''}`} onClick={() => setPage('notifications')}><div><Icon icon="notifications"/></div></div>
            <div className={`quick-navigation__entry ${showMobileMenu && 'quick-navigation__entry--active'}`} onClick={() => setShowMobileMenu(!showMobileMenu)}><Icon icon="menu" /></div>
          </div>
        </div>          
      <div className={`navigation__entries ${showMobileMenu && 'navigation__entries--visible'}`}>
        <div onClick={() => onSetPage('')} className={`navigation__entry ${page === 'home' ? 'navigation__entry--active' : ''}`}><div className="navigation__entry-icon"><Icon icon="home"/></div>Home</div>
        <div onClick={() => onSetPage('explore')} className={`navigation__entry ${page === 'explore' ? 'navigation__entry--active' : ''}`}><div className="navigation__entry-icon"><Icon icon="explore"/></div>Explore</div>
        <div className={`navigation__entry ${page === 'messages' ? 'navigation__entry--active' : ''}`} onClick={() => setPage('messages')}><div className="navigation__entry-icon"><Icon icon="messages"/></div>Messages</div>
        <div className={`navigation__entry ${page === 'bookmarks' ? 'navigation__entry--active' : ''}`} onClick={() => setPage('bookmarks')}><div className="navigation__entry-icon"><Icon icon="bookmark"/></div>Bookmarks</div>
        <div className={`navigation__entry ${page === 'profile' ? 'navigation__entry--active' : ''}`} onClick={() => setPage('profile')}><img src="https://thumbnails.odycdn.com/optimize/s:64:0/quality:95/plain/https%3A%2F%2Fplayer.odycdn.com%2Fspeech%2F3099c0c1b1cd6fcd%3A8.png"  className="navigation__entry-icon" />Profile</div>
        <div className={`navigation__entry ${page === 'settings' ? 'navigation__entry--active' : ''}`} onClick={() => setPage('settings')}><div className="navigation__entry-icon"><Icon icon="settings"/></div>Settings</div>
      </div>      

      </div>
    </div>
  );
};

export default Navigation;