import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Content from "components/content";
import { useAppDispatch } from "redux/hooks";
import { getUser } from 'utils/feed-parser';
import './style.scss';

type User = {
  title: string;
  thumbnail: string;
  description: string;
};

const UserPage = () => {
  const appDispatch = useAppDispatch();
  const { user: handle } = useParams();
  const [user, setUser] = useState<User>();
  const [wallpaper, setWallpaper] = useState('');
  

  useEffect(() => {
    setUser(getUser(handle || ''));

    switch(handle){
      case 'kim_iversen': 
        setWallpaper('https://thumbnails.lbry.com/banner-UCoJTOwZxbvq8Al8Qat2zgTA');
        break;
      case 'nerdrotic': 
        setWallpaper('https://thumbnails.lbry.com/banner-UC5T0tXJN5CrMZUEJuz4oovw');
        break;
      case 'someordinarygamers': 
        setWallpaper('https://thumbnails.lbry.com/banner-UCtMVHI3AJD4Qk4hcbZnI9ZQ');
        break;
      case 'mental_outlaw': 
        setWallpaper('https://thumbnails.lbry.com/banner-UC7YOGHUfC1Tb6E4pudI9STA');
        break;
    }
  }, [handle]);
  
 const UserHeader = () => {
  return (user && user.title && 
    <>    
    <div className="user-header__navigation">
      <div className="user-header__navigation-back" onClick={() => window.history.back()}>⮜</div>
      <div className="user-header__user">
        {user.title}
        <label>50 Posts</label>
      </div>        
    </div>
    <div className="user-header">
      <div className="user-header__wallpaper" style={{ backgroundImage: `url('${wallpaper}')`}}>
        <img src={user.thumbnail} />
      </div>
      <div className="user-header__meta">
        <div className="user-header__follow">
          <div>Follow</div>
        </div>
      <h1>{user.title}</h1>
      <p>{user.description}</p>
      </div>
    </div>
    </>
  )
 }

  return (
    <>
      <UserHeader />
      <Content user={user} />
    </>
  );
};

export default UserPage;
