import { useEffect } from "react";
import Content from "components/content";
import { useAppDispatch } from "redux/hooks";

const HomePage = () => {
  const appDispatch = useAppDispatch();

  /*
  useEffect(() => {
    appDispatch(fetchPosts(getOptions(undefined, undefined)));
  }, [appDispatch]);
  */

  return (
    <>    
      <div className="content__header"><h1>Home</h1></div>
      <Content home/>
    </>
  );
};

export default HomePage;
