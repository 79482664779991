import Routes from "./routes";
import Navigation from './components/navigation';
import Explorer from './components/explorer';
import Controls from './components/controls';

function App() {
  return (
    <div className="app">
      <header>
      </header>
      <div className="app-wrapper">
        <div className="app-container">
        <Navigation />
        <div className="app-content">
          <Routes />
        </div>
        <Explorer />
        </div>
      </div>
      <Controls />
    </div>
  );
}

export default App;
