import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import playerReducer from "redux/player/playerSlice";
import { playerPersistConfig } from "./persistence";

function createRootReducer() {
  return combineReducers({
    player: persistReducer<PlayerReducer>(playerPersistConfig, playerReducer.reducer),

  });
}

export const rootReducer = createRootReducer();
export type RootReducer = ReturnType<typeof rootReducer>;
export type PlayerReducer = ReturnType<typeof playerReducer.reducer>;
