import { useRef, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { selectActiveMediaId, setMedia } from "redux/player/playerSlice";
import Icon from 'components/icons'
import "./style.scss";

const Post = (props: any) => {
  const { post } = props;
  const dispatch = useAppDispatch();

  function timeSince(timestamp: any) {
    const now = Date.now();
    const postTimestamp = new Date(post.timestamp).getTime();

    if (!isNaN(postTimestamp)) {
      const diffInMinutes = Math.floor((now - postTimestamp) / (1000 * 60));

      if (diffInMinutes < 60) {
        return `${diffInMinutes}m ago`;
      } else if (diffInMinutes < 1440) {
        const hours = Math.floor(diffInMinutes / 60);
        return `${hours}h ago`;
      } else {
        const days = Math.floor(diffInMinutes / 1440);
        return `${days}d ago`;
      }
    } else {
      return 'Invalid timestamp';
    }
  }

  function setVideo(post: any, container: any){
    const rect = container.current.getBoundingClientRect();
    const top = container.current.offsetTop;

    container = {
      x: rect.x,
      y: rect.y,
      width: rect.width,
      height: rect.height,
      top: top,
      left: rect.left,
    };

    dispatch(setMedia({ post: post, container: container }));
  }

  const VideoPlaceholder = (props: any) => {
    const { post } = props;
    const playerContainerRef = useRef<HTMLDivElement>(null);
    const isActiveMediaId = useAppSelector((state) => selectActiveMediaId(state)) === post?.id || false;

    useEffect(() => {
      const handleResize = () => {
        if (isActiveMediaId) {
          setVideo(post, playerContainerRef)
        }
      }

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [isActiveMediaId])
    useEffect(() => {
      if(isActiveMediaId){
        setVideo(post, playerContainerRef);
      }
    },[isActiveMediaId])

    return (
      <div className="video-placeholder" onClick={(e) => e.preventDefault()} style={{ backgroundImage: `url(${post.thumbnail})` }} ref={playerContainerRef}>
        <div className="video-placeholder__play" onClick={() => setVideo(post, playerContainerRef)}><Icon icon="play" /></div>
      </div>
    )
  }

  return (
    <div className="post">
      <div className="post__avatar">
        <img src={post.channel.thumbnail} />
      </div>
      <div className="post__info">
        <div className="post__author">
          <div className="name"><NavLink to={`/${post.channel.handle.slice(1)}`}>{post.channel.title}</NavLink></div>
          <div className="handle">{post.channel.handle}</div>
          <NavLink className="timestamp" to={`/${post.channel.handle.slice(1)}/posts/${post.id}`}><span>·</span><div>{timeSince(post.timestamp)}</div></NavLink>
          <div className='menu'>🢗</div>
        </div>
        <NavLink to={`/${post.channel.handle.slice(1)}/posts/${post.id}`}>
          <div className="post__content">
            {post.title}
            <VideoPlaceholder post={post} />
          </div>
        </NavLink>
        <div className="post__actions">
          <div className="post__action__wrapper">
            <div className="post__action post__action--comments">
              <Icon icon="comments" />0
            </div>
          </div>
          <div className="post__action__wrapper">
            <div className="post__action post__action--reposts">
              <Icon icon="repost" />0
            </div>
          </div>
          <div className="post__action__wrapper">
            <div className="post__action post__action--likes">
              <Icon icon="like" />0
            </div>
          </div>
          <div className="post__action__wrapper">
            <div className="post__action post__action--bookmark">
              <Icon icon="bookmark" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;
