import { Route, Routes as Switch } from "react-router-dom";

import HomePage from "pages/home";
import ExplorePage from "pages/explore";
import UserPage from "pages/user";
import PostPage from "pages/post";

function Routes() {
  return (
    <Switch>
      <Route path="/" element={<HomePage />} />
      <Route path="/explore" element={<ExplorePage />} />
      <Route path="/:user" element={<UserPage />} />
      <Route path="/:user/posts/:postId" element={<PostPage />} />
    </Switch>
  );
}

export default Routes;
