import * as React from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
  icon?: string;
  strokeWidth?: string;
  color?: string;
  size?: number;
  width?: number;
  height?: number;
  ref: React.RefObject<SVGSVGElement>;
}

interface IconsType {
  [key: string]: React.ReactElement;
}
const strokes: IconsType = {
  photo: (
    <path d="M21.3333 2.66667V21.3333H2.66667V2.66667H21.3333ZM21.3333 0H2.66667C1.2 0 0 1.2 0 2.66667V21.3333C0 22.8 1.2 24 2.66667 24H21.3333C22.8 24 24 22.8 24 21.3333V2.66667C24 1.2 22.8 0 21.3333 0ZM14.8533 11.8133L10.8533 16.9733L8 13.52L4 18.6667H20L14.8533 11.8133Z" />
  ),
  comments: (
    <g>
      <path d="M20.25 6H19v2h1v8h-3a1 1 0 0 0-1 1v1.586l-2.293-2.293A1 1 0 0 0 13 16h-2.172l-2 2h3.758l3.707 3.707A1 1 0 0 0 18 21v-3h2.25A1.752 1.752 0 0 0 22 16.25v-8.5A1.752 1.752 0 0 0 20.25 6z"/>
      <path d="M7 18a1 1 0 0 1-1-1v-3H3.75A1.752 1.752 0 0 1 2 12.25v-8.5A1.752 1.752 0 0 1 3.75 2h12.5A1.752 1.752 0 0 1 18 3.75v8.5a1.762 1.762 0 0 1-.514 1.238A1.736 1.736 0 0 1 16.25 14h-4.836l-3.707 3.707A1 1 0 0 1 7 18zm-3-6h3a1 1 0 0 1 1 1v1.586l2.293-2.293A1 1 0 0 1 11 12h5V4H4v8z"/>
    </g>
  ),
  like: (
    <path d="M20.16,5A6.29,6.29,0,0,0,12,4.36a6.27,6.27,0,0,0-8.16,9.48l6.21,6.22a2.78,2.78,0,0,0,3.9,0l6.21-6.22A6.27,6.27,0,0,0,20.16,5Zm-1.41,7.46-6.21,6.21a.76.76,0,0,1-1.08,0L5.25,12.43a4.29,4.29,0,0,1,0-6,4.27,4.27,0,0,1,6,0,1,1,0,0,0,1.42,0,4.27,4.27,0,0,1,6,0A4.29,4.29,0,0,1,18.75,12.43Z"/>
  ),
  dislike: (
    <path d="M19 15H23V3H19M15 3H6C5.17 3 4.46 3.5 4.16 4.22L1.14 11.27C1.05 11.5 1 11.74 1 12V14C1 14.5304 1.21071 15.0391 1.58579 15.4142C1.96086 15.7893 2.46957 16 3 16H9.31L8.36 20.57C8.34 20.67 8.33 20.77 8.33 20.88C8.33 21.3 8.5 21.67 8.77 21.94L9.83 23L16.41 16.41C16.78 16.05 17 15.55 17 15V5C17 4.46957 16.7893 3.96086 16.4142 3.58579C16.0391 3.21071 15.5304 3 15 3Z" />
  ),
  lock: (
    <path d="M10.1 13.45C9.73466 13.4457 9.37678 13.5535 9.07453 13.7588C8.77228 13.964 8.54018 14.257 8.40946 14.5982C8.27874 14.9393 8.25568 15.3124 8.34338 15.6671C8.43108 16.0218 8.62534 16.3411 8.9 16.582V18.25C8.9 18.5683 9.02643 18.8735 9.25147 19.0985C9.47652 19.3236 9.78174 19.45 10.1 19.45C10.4183 19.45 10.7235 19.3236 10.9485 19.0985C11.1736 18.8735 11.3 18.5683 11.3 18.25V16.582C11.5747 16.3411 11.7689 16.0218 11.8566 15.6671C11.9443 15.3124 11.9213 14.9393 11.7905 14.5982C11.6598 14.257 11.4277 13.964 11.1255 13.7588C10.8232 13.5535 10.4653 13.4457 10.1 13.45ZM16.1 8.65V6.25C16.1 4.6587 15.4679 3.13258 14.3426 2.00736C13.2174 0.882141 11.6913 0.25 10.1 0.25C8.5087 0.25 6.98258 0.882141 5.85736 2.00736C4.73214 3.13258 4.1 4.6587 4.1 6.25V8.65C3.14522 8.65 2.22955 9.02928 1.55442 9.70442C0.879285 10.3795 0.5 11.2952 0.5 12.25V20.65C0.5 21.6048 0.879285 22.5205 1.55442 23.1956C2.22955 23.8707 3.14522 24.25 4.1 24.25H16.1C17.0548 24.25 17.9705 23.8707 18.6456 23.1956C19.3207 22.5205 19.7 21.6048 19.7 20.65V12.25C19.7 11.2952 19.3207 10.3795 18.6456 9.70442C17.9705 9.02928 17.0548 8.65 16.1 8.65ZM6.5 6.25C6.5 5.29522 6.87928 4.37955 7.55442 3.70442C8.22955 3.02928 9.14522 2.65 10.1 2.65C11.0548 2.65 11.9705 3.02928 12.6456 3.70442C13.3207 4.37955 13.7 5.29522 13.7 6.25V8.65H6.5V6.25ZM17.3 20.65C17.3 20.9683 17.1736 21.2735 16.9485 21.4985C16.7235 21.7236 16.4183 21.85 16.1 21.85H4.1C3.78174 21.85 3.47652 21.7236 3.25147 21.4985C3.02643 21.2735 2.9 20.9683 2.9 20.65V12.25C2.9 11.9317 3.02643 11.6265 3.25147 11.4015C3.47652 11.1764 3.78174 11.05 4.1 11.05H16.1C16.4183 11.05 16.7235 11.1764 16.9485 11.4015C17.1736 11.6265 17.3 11.9317 17.3 12.25V20.65Z" />
  ),
  repost: (
    <path d="M19 7a1 1 0 0 0-1-1h-8v2h7v5h-3l3.969 5L22 13h-3V7zM5 17a1 1 0 0 0 1 1h8v-2H7v-5h3L6 6l-4 5h3v6z"/>
  ),
  bookmark: (
    <path  fillRule="evenodd" d="M7 2a3 3 0 0 0-3 3v15.138a1.5 1.5 0 0 0 2.244 1.303l5.26-3.006a1 1 0 0 1 .992 0l5.26 3.006A1.5 1.5 0 0 0 20 20.138V5a3 3 0 0 0-3-3H7z" clipRule="evenodd"/>
  ),
  play: (
    <path d="M17.2839 11.134C17.9506 11.5189 17.9506 12.4811 17.2839 12.866L6.71601 18.9674C6.04934 19.3523 5.21601 18.8712 5.21601 18.1014L5.21601 5.8986C5.21601 5.1288 6.04934 4.64768 6.71601 5.03258L17.2839 11.134Z" />
  ),
  pause: (
    <path d="M20.2 3h-4.4a.8.8 0 0 0-.8.8v16.4a.8.8 0 0 0 .8.8h4.4a.8.8 0 0 0 .8-.8V3.8a.8.8 0 0 0-.8-.8ZM8.2 3H3.8a.8.8 0 0 0-.8.8v16.4a.8.8 0 0 0 .8.8h4.4a.8.8 0 0 0 .8-.8V3.8a.8.8 0 0 0-.8-.8Z"  strokeMiterlimit="10"/>
  ),
  home: (
    <path d="M12 3s-6.186 5.34-9.643 8.232c-.203.184-.357.452-.357.768 0 .553.447 1 1 1h2v7c0 .553.447 1 1 1h3c.553 0 1-.448 1-1v-4h4v4c0 .552.447 1 1 1h3c.553 0 1-.447 1-1v-7h2c.553 0 1-.447 1-1 0-.316-.154-.584-.383-.768-3.433-2.892-9.617-8.232-9.617-8.232z"/>
  ),
  notifications: (
    <path d="M12 5.5C14.7614 5.5 17 7.73858 17 10.5V12.7396C17 13.2294 17.1798 13.7022 17.5052 14.0683L18.7808 15.5035C19.6407 16.4708 18.954 18 17.6597 18H6.34025C5.04598 18 4.35927 16.4708 5.21913 15.5035L6.4948 14.0683C6.82022 13.7022 6.99998 13.2294 6.99998 12.7396L7 10.5C7 7.73858 9.23858 5.5 12 5.5ZM12 5.5V3M10.9999 21H12.9999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  ),
  messages: (
    <g>
      <rect x="3" y="6.5" width="18" height="14" rx="2" ry="2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <polyline points="4 8.2 12 14.1 20 8.2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>    
    </g>
  ),
  settings: (
    <g>
      <path d="M12.22 2h-.44a2 2 0 00-2 2v.18a2 2 0 01-1 1.73l-.43.25a2 2 0 01-2 0l-.15-.08a2 2 0 00-2.73.73l-.22.38a2 2 0 00.73 2.73l.15.1a2 2 0 011 1.72v.51a2 2 0 01-1 1.74l-.15.09a2 2 0 00-.73 2.73l.22.38a2 2 0 002.73.73l.15-.08a2 2 0 012 0l.43.25a2 2 0 011 1.73V20a2 2 0 002 2h.44a2 2 0 002-2v-.18a2 2 0 011-1.73l.43-.25a2 2 0 012 0l.15.08a2 2 0 002.73-.73l.22-.39a2 2 0 00-.73-2.73l-.15-.08a2 2 0 01-1-1.74v-.5a2 2 0 011-1.74l.15-.09a2 2 0 00.73-2.73l-.22-.38a2 2 0 00-2.73-.73l-.15.08a2 2 0 01-2 0l-.43-.25a2 2 0 01-1-1.73V4a2 2 0 00-2-2z" />
      <circle cx="12" cy="12" r="3" />
    </g>
  ),
  explore: (
    <g id="icons_Q2" data-name="icons Q2">
      <path d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"  strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.87868 9.87868L15.5355 8.46447L14.1213 14.1213L8.46447 15.5355L9.87868 9.87868Z"  strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  ),
  bright: (
    <g>
      <circle cx="12" cy="12" r="5" />
      <line x1="12" y1="1" x2="12" y2="3" />
      <line x1="12" y1="21" x2="12" y2="23" />
      <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
      <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
      <line x1="1" y1="12" x2="3" y2="12" />
      <line x1="21" y1="12" x2="23" y2="12" />
      <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
      <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
    </g>
  ),
  dark: (
    <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" />
  ),
  volume: (
    <g>
	    	<path d="M15,21v-2c3.86,0,7-3.14,7-7s-3.14-7-7-7V3c4.96,0,9,4.04,9,9S19.96,21,15,21z M15,17v-2c1.65,0,3-1.35,3-3s-1.35-3-3-3V7 c2.76,0,5,2.24,5,5S17.76,17,15,17z M1,12v4h5l6,5V3L6,8H1V12"/>
    </g>
  ),
  menu: (
    <g>
      <line x1="3" y1="12" x2="21" y2="12"></line>
      <line x1="3" y1="6" x2="21" y2="6"></line>
      <line x1="3" y1="18" x2="21" y2="18"></line>
    </g>
  )
};

const Icon = React.forwardRef<SVGSVGElement, SVGRProps>((props, ref) => {
  // const { title, titleId, icon = "fetch", strokeWidth = "2", color } = props;

  const buildIcon = (props: SVGRProps) => {
    const {
      size = 24,
      width = 24,
      height = 24,
      color = 'black',
      title,
      titleId,
      icon = 'fetch',
      ref,
      ...otherProps
    } = props;
    const currentStrokes = strokes[icon];
    return (
      <svg
        ref={ref}
        className={`icon icon--${icon}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${width} ${height}`}
        width={size}
        height={size}
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...otherProps}
      >
        {currentStrokes}
        {title && <title>{title}</title>}
      </svg>
    );
  };

  return buildIcon(props);
});

export default Icon;