// DEL import Parser from 'rss-parser';
// DEL import 'stream-http';
// DEL import rssToJson from 'rss-to-json';
import { useEffect } from 'react';
import axios from 'axios';

const feeds = [
  'https://odysee.com/$/rss/@KimIversen:d',
  'https://odysee.com/$/rss/@AlphaNerd:8',
  // 'https://odysee.com/$/rss/@GTV-Japan:d',
  'https://odysee.com/$/rss/@Nerdrotic:3',
  // 'https://odysee.com/$/rss/@eevblog:7',
  // 'https://odysee.com/$/rss/@UpperEchelonGamers:3',
  'https://odysee.com/$/rss/@SomeOrdinaryGamers:a'
]
const channels: any = []
const posts: any = []


/*
export function useSortPostsWhenChanged(posts: any) {
  useEffect(() => {
    if (posts.length) {
      console.log('sort');
    }
  }, [posts]);
}
*/

async function fetchDataAndSortPosts() {
  console.log('Fetching data...');
  await parseFeeds();
  if (posts.length) {
    let i = 0;
    for(let post of posts){
      post.id = i;
      i++;
    }
    sortPostsByTimestamp(posts)
  }
}

function sortPostsByTimestamp(posts: { timestamp: number }[]) {
  posts.sort((postA, postB) => {
    
    const timestampA = new Date(postA.timestamp).getTime();
    const timestampB = new Date(postB.timestamp).getTime();
    if (timestampA < timestampB) {
      return 1;
    } else if (timestampA > timestampB) {
      return -1;
    } else {
      return 0;
    }
  });
}

fetchDataAndSortPosts();


export function getChannels(){
  return channels;
}

export function getPost(postId: string){
  return posts.find((post: { id: string }) => Number(post.id) === Number(postId));
}

export function getUser(user: string){
  return channels.find((channel: { handle: string }) => channel.handle === '@' + user);
}

export function getPosts(start: number, amount: number, userHandle?: string){
  type Post = {
    channel: {
      handle: string;
    };
  };
  if(userHandle){
    const res = posts.filter((post: Post) => post.channel.handle === userHandle)
    return res.slice(start, start + amount);

  }else{
    return posts.slice(start, start + amount);
  }  
}

export async function parseFeeds() {
  const fetchPromises = feeds.map((feed) => fetchRssFeed(feed));

  try {
    await Promise.all(fetchPromises);
    console.log('All feeds have been fetched.');
  } catch (error) {
    console.error('Error fetching one or more feeds:', error);
  }
}

/*
async function fetchRssFeed(feedUri: any) {
  const parser = new Parser();
  const feed = await parser.parseURL(feedUri);
  return feed.items; // This will contain the list of items in the RSS feed
}
*/

async function fetchRssFeed(feedUri: any) {
  try {
    const CORS_PROXY = 'https://corsproxy.io/?';

    const response = await axios.get(CORS_PROXY + encodeURIComponent(feedUri));        
    const parser = new DOMParser();    
    const xmlDoc = parser.parseFromString(response.data, 'text/xml');     
    let title = Array.from(xmlDoc.querySelectorAll('channel'))[0].childNodes[0].textContent?.replace(" on Odysee", "") || '';
    const channel = {
      title: title,
      handle: '@' + title.replace(/\s+/g, '_').toLowerCase(),
      thumbnail:(Array.from(xmlDoc.querySelectorAll('channel'))[0].childNodes[3] as Element)?.querySelector('url')?.textContent,
      description: Array.from(xmlDoc.querySelectorAll('channel'))[0].childNodes[1].textContent
    }
    
    channels.push(channel);
    const items = Array.from(xmlDoc.querySelectorAll('item'));    

    const parsedItems = items.map((item) => {      
      return {
        channel: channel,
        title: item.querySelector('title')?.textContent,
        link: item.querySelector('link')?.textContent,
        description: item.querySelector('description')?.textContent,
        uri: item.querySelector('enclosure')?.getAttribute('url'),
        thumbnail: (item.querySelector('itunes\\:image') || item.querySelector('image'))?.getAttribute('href'),
        timestamp:item.querySelector('pubDate')?.textContent,
      }
    });

    // console.log('thumbnail c: ', parsedItems[0].thumbnail)
    posts.push(...parsedItems)    
  } catch (error) {
    console.error('Error parsing RSS feed:', error);
  }
}