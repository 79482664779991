import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { getChannels } from 'utils/feed-parser'
import Icon from 'components/icons'
import "./style.scss";

const Explorer = () => {
  const [searchValue, setSearchValue] = useState('');
  const [showNotifications, setShowNotifications] = useState(false);

  interface Channel {
    title: string,
    thumbnail: string,
  }
  const [channels, setChannels] = useState([])  

  const handleSearchInputChange = (e: any) => {
    setSearchValue(e.target.value);
  };
  
  useEffect(() => {
    setTimeout(() => {
      let test = getChannels()
      setChannels(test)
    },3000)    
  }, [])

  const MessagePreview = (props: any) => {
    const { channel } = props;
    return (
      <div className="explorer__card-row">
        <div className="message-preview">
          <img className="message-preview__thumbnail" />
          <div className="message-preview__infos">
            <div className="message-preview__title">Username<div/></div>
            <div className="message-preview__text">Just another test...</div>
          </div>        
        </div>
      </div>
    )
  }

  const ChannelPreview = (props: any) => {
    const { channel } = props;
    return (
      <NavLink to={`/${channel.handle.slice(1)}`}>
        <div className="channel-preview">
          <img src={channel.thumbnail} className="channel-preview__thumbnail" />
          <div className="channel-preview__infos">
            <div className="channel-preview__title">{channel.title}</div>
            <div className="channel-preview__handle">{channel.handle}</div>
          </div>        
        </div>
      </NavLink>
    )
  }

  return (
    <div className="explorer__wrapper">
    <div className="explorer">
      <div className="explorer__card">
        <div className="explorer__card-tabs">
          <div className={`explorer__card-tab`} onClick={() => setShowNotifications(!showNotifications)}>
            <Icon icon="notifications"/>
            Notifications
            <div className="bubble">3</div>
          </div>
        </div>
        <div className="explorer__card-tab-content" style={{ height: showNotifications ? '210px' : 0}}>
          <MessagePreview />
          <MessagePreview />
          <MessagePreview />
        </div>
      </div>
      <div className="explorer__search">
        <input type="text" placeholder="Search" value={searchValue} onChange={handleSearchInputChange}/>
        {searchValue.length>0 && <div onClick={() => setSearchValue('')}>✕</div>}
      </div>
      {/*
      <div className="explorer__card">
        <div className="explorer__card-title">
          New to Placeholder?
        </div>
      </div>
      */}
      <div className="explorer__card">
        <div className="explorer__card-title">
          <Icon icon="explore" />Who to follow
        </div>
                
        {channels && channels.map((channel: Channel, i) => (
          <div className="explorer__card-row" key={i}>
            <ChannelPreview channel={channel} />
          </div>
        ))}
        <div className="explorer__card-row explorer__card-row--more">
          Show more
        </div>
        
      </div>
    </div>
    </div>
  );
};

export default Explorer;