import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from './app';
import Player from './components/player';
import { persistor, store } from "./store";

import './style.scss';

function AppWrapper() {
  const Rehydrating = () => {
    return <div className="rehydrating" />;
  };

  function onReduxReady() {
    /*
    const gatewayId = store.getState().settings.gatewayId;
    Ar.initialize(gatewayId);
    initializeWarp(gatewayId, Ar.arweave);
    */
  }

  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={<Rehydrating />} persistor={persistor} onBeforeLift={onReduxReady}>
          <HashRouter>
            <Player />
            <App />            
          </HashRouter>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
}

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement as HTMLElement);
root.render(<AppWrapper />);