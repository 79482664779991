import { useEffect, useState, useRef } from 'react';
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import Icon from 'components/icons'
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { setPlayerMode } from "redux/player/playerSlice";

import "./style.scss";

const Controls = () => {
  const [meta, setMeta] = useState({ title: '', channel: { title: '', thumbnail: '', handle: '' }, id: '' });
  const [status, setStatus] = useState('stop');
  const [progress, setProgress] = useState('0:00');
  const [duration, setDuration] = useState('0:00');
  const [progressPercent, setProgressPercent] = useState(0);
  const [fullscreen, setFullscreen] = useState(false);  
  const [hideControls, setHideControls] = useState(false);
  const [timeStampPosition, setTimeStampPosition] = useState(0);
  const statusRef = useRef(status)
  const playerProps: any = useAppSelector((state) => state.player.playerProps);  
  const activePlayerMode: any = useAppSelector((state) => state.player.playerMode);
  const player = document.querySelector(".player") as HTMLVideoElement;
  const dispatch = useAppDispatch();  

  useEffect(() => {
    if(playerProps){
      setMeta(playerProps.post);
    }    
  },[playerProps])

  useEffect(() => {
    if(meta){
      player && player.play();
    }    
  },[meta])

  function toggleStatus(){
    if(status === 'play') player.pause()
    else if(status === 'pause') player.play()
  }

  function getTimecode(ms: number) {
    const totalSeconds = Math.floor(ms);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    const formattedTime = `${formattedMinutes}:${formattedSeconds}`;
  
    return formattedTime;
  }

  useEffect(() => {
    const handlePlay = () => {
      setStatus('play');
      statusRef.current = 'play';
    };
    const handlePause = () => {
      setStatus('pause');
      statusRef.current = 'pause';
    };
    const handleStop = () => {
      setStatus('stop');
      statusRef.current = 'stop';
    };
    const handleProgress = () => {
      const currentTime = player.currentTime;
      const duration = player.duration;
      const progress = (currentTime / duration) * 100;
      setProgress(getTimecode(currentTime))
      setProgressPercent(progress)
      setDuration(getTimecode(duration));
    };

    if(player){      
      player.addEventListener("play", handlePlay);
      player.addEventListener("pause", handlePause);
      player.addEventListener("stop", handleStop);
      player.addEventListener('timeupdate', handleProgress);
    }    

    return () => {
      if(player){
        player.removeEventListener("play", handlePlay);
        player.removeEventListener("pause", handlePause);
        player.removeEventListener("stop", handlePlay);
        player.removeEventListener("timeupdate", handlePlay);
      }
    };
  }, [player]);

  useEffect(() => {
    const navigation = document.querySelector(".navigation");
    if(hideControls){
      navigation?.classList.add('navigation--hidden');
    }else{
      navigation?.classList.remove('navigation--hidden');
    }
  },[hideControls])

  function toggleFullscreen(){
    if(player){
      const doc: any = document
      if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) { 
        if (doc.documentElement.requestFullscreen) {
          doc.documentElement.requestFullscreen();
        } else if (doc.documentElement.mozRequestFullScreen) {
          doc.documentElement.mozRequestFullScreen();
        } else if (doc.documentElement.webkitRequestFullscreen) {
          doc.documentElement.webkitRequestFullscreen();
        } else if (doc.documentElement.msRequestFullscreen) {
          doc.documentElement.msRequestFullscreen();
        }
        document.body.style.overflowY = 'hidden';
        dispatch(setPlayerMode('fullscreen'));        
        setFullscreen(true);
      }else{
        if (doc.exitFullscreen) {
          doc.exitFullscreen();
        } else if (doc.mozCancelFullScreen) {
          doc.mozCancelFullScreen();
        } else if (doc.webkitExitFullscreen) {
          doc.webkitExitFullscreen();
        } else if (doc.msExitFullscreen) {
          doc.msExitFullscreen();
        }
        document.body.style.overflowY = 'scroll';
        dispatch(setPlayerMode('inline'));
        setFullscreen(false);
      }
    }
  }

  useEffect(() => {
    let timer: any;
    document.addEventListener('mousemove', () => {
      clearTimeout(timer);
      setHideControls(false);      
      
      timer = setTimeout(() => {
        fullscreen && statusRef.current === 'play' && setHideControls(true)
      }, 3000);
    });

    return () => {
      document.removeEventListener("mousemove", () => setHideControls(false));
    };
  },[fullscreen])

  function onHover(e: any) {
    const hoverPos = e.target.getBoundingClientRect();
    const hoverPosPercent = ((e.clientX - hoverPos.left) / hoverPos.width) * 100;
    setTimeStampPosition(hoverPosPercent);
  }
  function setPosition(position:number) {
    player.currentTime = position;
  }

  return (
    <div 
      className={classnames("controls__wrapper", {
        "controls__wrapper--disabled": meta.title === '',
        "controls__wrapper--fullscreen": fullscreen,
        "controls__wrapper--hidden": fullscreen && hideControls,
      })}
    >
      <div className="controls">
        <div className="controls__main">
          <div className="button status" onClick={() => toggleStatus()}><Icon icon={status === 'play' ? 'pause' : 'play'} /></div>
          <div className="button volume"><Icon icon="volume" /></div>
          <div className="timecodes">{progress} / {duration}</div>
        </div>        
        <div className="controls__sub">
          <div className="controls__progress">
            <div className="controls__meta">
              {meta && meta.title !== '' && (
                <>
                <NavLink to={`/${meta.channel.handle.slice(1)}`}><img src={meta.channel.thumbnail} />{ meta.channel.title }</NavLink>&nbsp;|&nbsp;
                <NavLink to={`/${meta.channel.handle.slice(1)}/posts/${meta.id}`}>{ meta.title }</NavLink>
                </>
              )}            
            </div>          
            <div 
              className="progress-bar"
              onMouseMove={(e) => onHover(e)}
              onMouseLeave={() => setTimeStampPosition(0)}
              onClick={() => setPosition(player.duration/100*timeStampPosition)}
            >
              <div className="progress-bar__bar" />
              <div className="progress-bar__progress" style={{ width: `${progressPercent}%`}}/>
              
              {timeStampPosition > 0 && (
                <>
                  <div className="progress-bar__position" style={{ left: `${progressPercent}%`}}/>            
                  <div className="progress-bar__timestamp" style={{ left: `${timeStampPosition}%`}}>{}{getTimecode(player.duration/100*timeStampPosition)}</div>
                </>
              )}
            </div>          
          </div>        
          <div className="player-modes">
            <div className={`button button--player-mode ${activePlayerMode === 'small' && !fullscreen && 'button--player-mode--active'} ${fullscreen && 'button--player-mode--disabled'}`}>
              <div className="mode--small" />
            </div>
            <NavLink to={`/${meta.channel.handle.slice(1)}/posts/${meta.id}`} className={`${fullscreen && 'button--player-mode--disabled'}`}>
              <div className={`button button--player-mode ${activePlayerMode === 'inline' && !fullscreen && 'button--player-mode--active'}`}>
                <div className="mode--inline" />
              </div></NavLink>
            <div className={`button button--player-mode ${fullscreen && 'button--player-mode--active'}`} onClick={() => toggleFullscreen()}><div className="mode--fullscreen" /></div>
          </div>        
        </div>        
      </div>      
    </div>
  );
};

export default Controls;