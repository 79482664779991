

import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from "classnames";
import Icon from 'components/icons'
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { setPlayerMode } from 'redux/player/playerSlice';
import "./style.scss";

const Player = (props: any) => {
  const [id, setId] = useState(99999);
  const [uri, setUri] = useState('');
  const modeRef = useRef('hidden');
  const [mode, setMode] = useState('hidden');
  const [status, setStatus] = useState('stop');
  const playerRef = useRef<HTMLVideoElement | null>(null);
  const playerWrapperRef = useRef<HTMLDivElement>(null);
  const playerProps: any = useAppSelector((state) => state.player.playerProps);
  const playerMode: any = useAppSelector((state) => state.player.playerMode);
  const mediaId: any = useAppSelector((state) => state.player.mediaId);
  const location = useLocation();
  const dispatch = useAppDispatch();  

  // tmp
  const locationLock = useRef<any>(null);
  
  function toggleStatus(){
    if(playerRef && playerRef.current){
      if(status === 'pause') playerRef.current.play()
      else if(status === 'play') playerRef.current.pause()
    }
  }
  
  useEffect(() => {
    const videoElement = playerRef.current;

    const handlePlay = () => {
      setStatus('play');
    };
    const handlePause = () => {
      setStatus('pause');
    };
    const handleStop = () => {
      setStatus('stop');
    };

    if(videoElement){
      videoElement.addEventListener("play", handlePlay);
      videoElement.addEventListener("pause", handlePause);
      videoElement.addEventListener("stop", handleStop);
    }

    return () => {
      videoElement && videoElement.removeEventListener("play", handlePlay);
    };
  }, [playerRef]);

  useEffect(() => {
    if(mode){
      dispatch(setPlayerMode(mode));
    }
  },[mode]) 
  
  useEffect(() => {
    if(playerMode){
      setMode(playerMode);
    }
  },[playerMode]) 
  
  useEffect(() => {
    if(playerProps){      
      setId(playerProps.post.id);
      setUri(playerProps.post.uri);
      if(playerProps.post.id !== mediaId || mode === 'hidden') setMode('inline');
  
      if (playerWrapperRef.current) {
        playerWrapperRef.current.style.top = `${playerProps.container.top}px`;
        playerWrapperRef.current.style.left = `${playerProps.container.left}px`;
        playerWrapperRef.current.style.width = `${playerProps.container.width}px`;
      }
    } 
    window.addEventListener('scroll', handleScroll);      
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  },[playerProps])  

  useEffect(() => {
    // tmp fix on page change
    if(mode !== 'fullscreen'){
      if(location && locationLock.current && locationLock.current !== location.pathname){
        if(playerProps){
          setMode('small');
          dispatch(setPlayerMode('small'));
          modeRef.current = 'small';
        }      
      }else if(playerProps){
        if(playerProps.post.id === mediaId){
          setMode('inline');
          dispatch(setPlayerMode('inline'));
          modeRef.current = 'inline';
        }      
      }
    }    
    locationLock.current = location.pathname
  }, [location, playerProps]);

  function handleScroll(e: any) { 
    if(playerProps && location.pathname === locationLock.current){
      const scrollTop = e.target.documentElement.scrollTop;
      const top = playerProps.container.top;
      if(scrollTop < top + 200 && scrollTop > top - window.innerHeight + 200){
        setMode('inline')
        modeRef.current = 'inline'
      }
      else{
        setMode('small')
        modeRef.current = 'small'
      }
    }    
  }

  return (
    <div
      ref={playerWrapperRef} 
      className={classnames("player__wrapper", {
        "player__wrapper--inline": mode === 'inline',
        "player__wrapper--small": mode === 'small',
        "player__wrapper--fullscreen": playerMode === 'fullscreen',
      })}
    >
      <div className="player__container">        
        <div className="player__aligner">
          <video 
            ref={playerRef}
            className="player"
            src={uri} 
            onClick={toggleStatus} />
        </div>
        <div className="player__spacer" />
        {false && status === 'pause' && <div className="player-play" onClick={toggleStatus}><Icon icon="play" /></div>}
      </div>
    </div>
  );
};

export default Player;