import { createSlice } from "@reduxjs/toolkit";
// import { RootState } from "store";
import { PlayerState } from "./playerSlice.types";

// ****************************************************************************
// Actions
// ****************************************************************************

export function showPlayer<T>(props: T) {
  return { type: "player/show", payload: { props } };
}

export function hidePlayer() {
  return { type: "player/hide" };
}

export function setMedia(props: any) {
  return { type: "player/set", payload: { props } };
}

export function clearMediaId() {
  return { type: "player/clearMediaId" };
}

export function setPlayerMode(props: any) {
  return { type: "player/setPlayerMode", payload: { props } };
}

export function setMediaStatus(props: any) {
  return { type: "player/status", payload: { props } };
}

// ****************************************************************************
// Selectors
// ****************************************************************************


export function selectActiveMediaId(state: any) {
  return state.player.mediaId;
}
/*
export function selectActivePlayerMode(state: any) {
  return state.playerMode;
}
*/

// ****************************************************************************
// playerSlice
// ****************************************************************************

const initialState = {
  player: null,
  playerProps: null,
  mediaId: null,
  playerMode: null
};

const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase("player/show", (state: PlayerState, action: any) => {
      state.player = null;
      state.playerProps = action.payload.props;
    });
    builder.addCase("player/hide", (state: PlayerState) => {
      state.player = null;
      state.playerProps = null;
    });
    builder.addCase("player/set", (state: PlayerState, action: any) => {
      state.player = null;
      state.playerProps = action.payload.props;
      state.mediaId = action.payload.props.post.id;
    });
    builder.addCase("player/clearMediaId", (state: PlayerState) => {
      state.mediaId = null;
    });
    builder.addCase("player/setPlayerMode", (state: PlayerState, action: any) => {      
      state.playerMode = action.payload.props;
    });
    builder.addCase("player/status", (state: PlayerState, action: any) => {
      state.player = null;
      state.playerProps = action.payload.props;
    });
  },
});

export default playerSlice;
